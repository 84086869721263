import React from 'react';
import { Flex, Textarea, Input, Button, Box, Heading } from 'theme-ui';

export default function ContactForm({
  formName,
  variantName,
  city,
  isMultiLocationSite,
}) {
  return (
    <Box sx={customStyles.container}>
      <Heading as='h2' sx={customStyles.heading}>
        Request Catering
      </Heading>
      <Flex
        as='form'
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactformV2'}
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <Input
          type='hidden'
          name='form-name'
          value={formName ? formName : 'contactformV2'}
        />
        <Input
          variant={`${variantName}.name`}
          name='name'
          type='text'
          id='name'
          mb={3}
          placeholder='Name'
          className='inputField name'
        />
        <Input
          variant={`${variantName}.email`}
          type='email'
          name='email'
          id='email'
          mb={3}
          placeholder='Email'
          className='inputField email'
        />
        <Input
          variant={`${variantName}.phone`}
          type='phone'
          name='phone'
          id='phone'
          mb={3}
          placeholder='Phone'
          className='inputField phone'
        />

        <Textarea
          name='message'
          id='message'
          variant={`${variantName}.message`}
          rows='6'
          mb={3}
          placeholder='Message'
          className='textField message'
        />
        {isMultiLocationSite ? (
          <Input
            sx={{ display: 'none !important' }}
            name='location'
            id='location'
            value={city}
            placeholder={city}
          />
        ) : (
          ''
        )}
        <Button className='button submitBtn'>Submit</Button>
      </Flex>
    </Box>
  );
}

const customStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '4rem 1rem',
    input: {
      '::-webkit-calendar-picker-indicator': {
        filter: 'brightness(0) invert(1)',
      },
    },
  },
  heading: {
    marginBottom: '2rem',
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    input: {
      width: '48%',
    },
  },
};
